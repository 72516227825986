import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { UsersApiService } from 'src/app/services/users/usersApi.service';

declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.template.html',
  styleUrls: ['navigation.template.scss'],
  providers: []
})

export class NavigationComponent implements OnInit, AfterViewInit {
  public usedData: any = {};
  public percentage: any;
  constructor(
    private router: Router,
    private usersApiService: UsersApiService,
  ) { }

  ngOnInit() {
    // this.usersApiService.getOrgProfile().subscribe(res => {
    //   this.usedData = res.org;
    //   this.percentage = Math.round(this.usedData.sizeUsed / this.usedData.size * 10000) / 100 + '%';
    // }, err => {
    //   if (err.error.message !== 'Failed to authenticate JWT token: No auth token') {
    //     swal('Groups error', err.error.message || 'Network error or server exception', 'error');
    //   }
    // });
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery('body').hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      });
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  correctHeight() {
    const pageWrapper = jQuery('#page-wrapper');
    const navbarHeight = jQuery('nav.navbar-default').height();
    const wrapperHeight = pageWrapper.height();

    if (navbarHeight > wrapperHeight) {
      pageWrapper.css('min-height', navbarHeight + 'px');
    }

    if (navbarHeight <= wrapperHeight) {
      if (navbarHeight < jQuery(window).height()) {
        pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px');
      } else {
        pageWrapper.css('min-height', navbarHeight + 'px');
      }
    }

    if (jQuery('body').hasClass('fixed-nav')) {
      if (navbarHeight > wrapperHeight) {
        pageWrapper.css('min-height', navbarHeight + 'px');
      } else {
        pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px');
      }
    }
  }
}
