import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  providers: [DatePipe]
})
export class TableCellComponent implements OnInit {

  @Input() row;
  @Input() column;

  public columnContent: any = '';
  constructor(private datePipe: DatePipe) { }

  ngOnInit() {

    const _columnType = this.column.dataType || '';
    const _columnFormat = this.column.format || '';
    const _columnContent = this.getColumnData();
    if (_columnType !== '') {
      if (_columnType === 'date' && _columnFormat !== '') {
        this.columnContent = this.datePipe.transform(_columnContent, _columnFormat);
      } else if (_columnType === 'date') {
        this.columnContent = this.datePipe.transform(_columnContent);
      }

      if (_columnType === 'boolean' && _columnFormat !== '') {
        if (_columnContent) {
          this.columnContent = _columnFormat.true;
        } else {
          this.columnContent = _columnFormat.false;
        }
      } else if (_columnType === 'boolean') {
        this.columnContent = _columnContent;
      }

      if (_columnType === 'array' && _columnFormat !== '') {
        this.columnContent = _columnContent.join(_columnFormat);
      } else if (_columnType === 'array') {
        this.columnContent = _columnContent.join();
      }


    } else {
      this.columnContent = _columnContent;
    }


  }




  getColumnData() {
    let _columnContent;
    if (this.column && this.column.data) {
      const _columnData = this.column.data;
      const _rowData = this.row;
      if (this.isArr(_columnData)) {
        // arr
        const charKeyArr = _columnData.split('.$.');

        _columnContent = _rowData[charKeyArr[0]].map(res => {
          return res[charKeyArr[1]];
        }).join(',');
      } else if (this.isObj(_columnData)) {
        // obj
        const charKeyArr = _columnData.split('.');

        _columnContent = _rowData[charKeyArr[0]][charKeyArr[1]];
      } else {
        _columnContent = _rowData[this.column.data];
      }

    } else {
      _columnContent = '';
    }

    return _columnContent;
  }


  isArr(column): Boolean {
    return new RegExp(/(\.\$\.){1,1}/).test(column);
  }


  isObj(column): Boolean {
    return new RegExp(/(\.){1,1}/).test(column);
  }



}
