import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionDirective } from './permission.directive';
import { TableModule } from '../components/table/table.module';
import { MiniNavbarModule } from '../components/mini-navbar/mini-navbar.module';

@NgModule({
    declarations: [
        PermissionDirective
    ],
    exports: [
        TranslateModule,
        PermissionDirective,
        TableModule,
        MiniNavbarModule
    ]
})
export class SharedModule { }
