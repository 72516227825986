import { Injectable } from '@angular/core';
import { BillService } from 'document-vault-ts-angular-client';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private billService: BillService
  ) { }

  getOrganzationBills(searchRequest) {
    return this.billService.getOrganzationBills(searchRequest);
  }

  getThisMonthOrganzationBill() {
    return this.billService.getThisMonthOrganzationBill();
  }

  getBillModes() {
    return this.billService.getBillModes();
  }

  subscribeBillMode(billingModeId, source, email) {
    return this.billService.subscribeBillMode(billingModeId, source, email);
  }

  downloadBills(searchRequest) {
    return this.billService.downloadBills(searchRequest);
  }
}
