import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UsersApiService } from 'src/app/services/users/usersApi.service';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-header',
  templateUrl: 'header.template.html',
  styleUrls: ['header.template.scss']
})

export class HeaderComponent implements OnInit {
  public _user: any;
  public language: String = '';
  // Whether the expiration time is show
  public isShow = true;
  public profileData = {
    org: {},
    user: {}
  };

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private usersApiService: UsersApiService,
  ) {

  }

  ngOnInit(): void {
    const topNavbarScope = this;
    this.zone.run(() => {
      this.usersApiService.profileSubject.subscribe({
        next: function (profile) {
          if (profile) {
            topNavbarScope._user = profile;
            switch (profile.locale) {
              case 'en': topNavbarScope.language = 'English'; break;
              case 'zh': topNavbarScope.language = '中文'; break;
              case 'zh-CN': topNavbarScope.language = '中文'; break;
              default:
                topNavbarScope.language = 'en';
                topNavbarScope._user.locale = 'en';
                break;
            }
            if (topNavbarScope._user.locale === 'zh-CN') {
              topNavbarScope._user.locale = 'zh';
            }
            topNavbarScope.translate.use(topNavbarScope._user.locale);
          }
        }
      });
    });
    this.getOrgProfile();
  }

  getOrgProfile() {
    this.usersApiService.getOrgProfile().subscribe((res: any) => {
      if (res.org.expirationDate === 0) {
        this.isShow = false;
      }
      this.profileData = res;
    }, err => {
      if (err.error.message !== 'Failed to authenticate JWT token: No auth token') {
        swal('OrgProfile error', err.error.message || 'Network error or server exception', 'error');
      } else {
        throw err;
      }
    });
  }

  changeLanguage(lang) {
    const topNavbarScope = this;
    this._user.locale = lang;
    this.usersApiService.updateProfile(this._user).subscribe(function () {
      switch (lang) {
        case 'en': topNavbarScope.language = 'English'; break;
        case 'zh': topNavbarScope.language = '中文'; break;
      }
      topNavbarScope.translate.use(lang);
    }, err => {
      swal('Profile error', err.error.message || 'Network error or server exception', 'error');
    });
  }

  upgrade_click() {
    $('#payModals').modal('show');
  }
}
