import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersApiService } from './services/users/usersApi.service';
import { AuthenticationApiService } from './services/authentication/authenticationApi.service';
import { ConfigurationService } from './services/configuration/configuration.service';

import { UserService } from 'document-vault-ts-angular-client';


declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthenticationApiService]
})
export class AppComponent implements OnInit {
  private tokenInter: any;
  private times: any;
  private expiryTime: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private usersApiService: UsersApiService,
    private authenticationApiService: AuthenticationApiService,
    private configurationService: ConfigurationService,
    private userService: UserService,
  ) {
    this.configurationService.setApiKeyToConfiguration(userService);
    // 加载语言
    translate.addLangs(['zh', 'en']);
    // 设置中文
    translate.setDefaultLang('en');
    // 获取当前浏览器语言
    // const broswerLang = translate.getBrowserLang();
    // translate.use(broswerLang.match(/en|zh/) ? broswerLang : 'en');
    // 设置过期时间,以及加载Profile
    router.events.subscribe((event: NavigationStart) => {
      if (event instanceof NavigationStart) {
        // 监视路由
        if (event.url === '/login' || event.url === '/') {
          if (this.usersApiService.profileSubject.value) {
            // clear JWT
            localStorage.clear();
            // 登陆页面清空登出计时器
            this.authenticationApiService.logout().subscribe(() => {
            }, err => {
              swal('Logout error', err.error.meessage, 'error');
            });
            // 登录页面清空订阅
            this.usersApiService.clear();
            window.clearInterval(this.tokenInter);
            window.clearInterval(this.times);
          }
        } else {
          // 判断当前是否存在已Profile 进行查询
          if (this.usersApiService.profileSubject.value === false) {
            this.usersApiService.getProfile().subscribe(res => {
              this.usersApiService.setProfile(res);
            }, (error) => {
              throw error;
            });
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.url !== '/login' && event.url !== '/') {
        const scope = this;
        // 清空计时器
        window.clearInterval(this.tokenInter);
        window.clearInterval(this.times);
        // 设置过期时间
        this.expiryTime = 3600000 + Date.now();
        this.tokenInter = window.setInterval(function () {
          console.log('tokenInter: ' + scope.expiryTime);
          if (scope.expiryTime <= Date.now() + 120000) {
            window.clearInterval(scope.tokenInter);

            if (scope.expiryTime > Date.now()) {
              $('#modal-sessionExpireWarningModal').modal('show');
              scope.times = window.setInterval(function () {
                const dateRange = scope.expiryTime - Date.now();
                let minute: any = Math.floor(dateRange / 60000);
                let second: any = Math.floor(dateRange / 1000 - minute * 60);

                console.log('timeInter: ' + scope.times);
                if (minute < 10) {
                  minute = '0' + minute;
                }
                if (second < 10) {
                  second = '0' + second;
                }
                const time = minute + ':' + second;
                $('#logoutTime').html(time);

                if (scope.expiryTime <= Date.now()) {
                  $('#modal-sessionExpireWarningModal').modal('hide');
                  window.clearInterval(scope.times);
                  scope.authenticationApiService.logout().subscribe(() => {
                  }, err => {
                    swal('Logout error', err.error.meessage, 'error');
                  });
                  console.log('auto logout!');
                  scope.router.navigate(['/login']);
                }
              }, 1000);
            } else {
              $('#modal-sessionExpireWarningModal').modal('hide');
              window.clearInterval(scope.times);
              scope.authenticationApiService.logout().subscribe(() => {
              }, err => {
                swal('Logout error', err.error.meessage, 'error');
              });
              scope.router.navigate(['/login']);
            }
          }
        }, 1000);
      }
    });
  }

  // 刷新
  refreshSession() {
    clearInterval(this.tokenInter);
    clearInterval(this.times);
    window.location.reload();
  }

  // 登出
  logout() {
    clearInterval(this.tokenInter);
    clearInterval(this.times);
    this.authenticationApiService.logout().subscribe(() => {
    }, err => {
      swal('Logout error', err.error.meessage, 'error');
    });
  }
}
