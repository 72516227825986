import { Directive, ElementRef, Input, OnInit } from '@angular/core';
// import { AuthenticationService } from 'services/authentication/authentication.service';
@Directive({
  selector: '[ilPermission]'
})
export class PermissionDirective implements OnInit {
  // @Input('ilPermission') permission: String = '';
  @Input() selectedColor: String = '';
  @Input() tColor: string;
  @Input() tSize: string;

  constructor(
    private el: ElementRef,
    // private authenticationService: AuthenticationService
    ) {

    this.el = el;
  }

  ngOnInit() {
    const topNavbarScope = this;

    // this.authenticationService.permissionSubject.subscribe({
    //   next: function (permissionSubject) {
    //     if (permissionSubject) {
    //       permissionSubject.filter(per => {
    //         if (topNavbarScope.permission === per.resourceId) {

    //           if (per.permission === 'CONTROL') {
    //             topNavbarScope.el.nativeElement.style.display = 'block';
    //           } else {
    //             topNavbarScope.el.nativeElement.style.display = 'none';
    //           }
    //         }
    //       });
    //     }
    //   }
    // });
  }

}

