import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor() { }

  setApiKeyToConfiguration(service) {
    service.configuration.apiKeys = {
      jwt: localStorage.getItem('jwt')
    };
  }
}
