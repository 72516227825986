import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

declare var swal: any;

@Injectable()
export class ErrorLogService {
    constructor(
    ) { }

    logError(error: any) {
        const errorInfo = error.error || '';
        if (error instanceof HttpErrorResponse) {
            console.error('There was an HTTP error.', errorInfo.message, 'Status code:', (<HttpErrorResponse>error).status);
        } else if (error instanceof TypeError) {
            console.error('There was a Type error.', errorInfo.message);
        } else if (error instanceof Error) {
            console.error('There was a general error.', errorInfo.message);
        } else {
            console.error('Nobody threw an error but something happened!', error);
        }

        const data = {
            code: '',
            message: errorInfo.message,
            browser: navigator.userAgent,
            stack: error.stack,
            level: 'error'
        };
        if (data.message === 'Failed to authenticate JWT token: No auth token' && error.status === 403) {
            swal({
                title: 'JWT error',
                text: data.message,
                icon: 'error',
                buttons: {
                    ok: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        closeModal: true
                    }
                }
            }).then((isOk) => {
                if (isOk) {
                    window.location.href = '/#/login';
                }
            });
        }
    }
}
