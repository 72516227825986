import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BasicLayoutComponent } from './layouts/basicLayout/basicLayout.component';
import { BlankLayoutComponent } from './layouts/blankLayout/blankLayout.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: './views/login/login.module#LoginModule'
      }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'billing',
        loadChildren: './views/billing/billing.module#BillingModule'
      },
      {
        path: 'groups',
        loadChildren: './views/groups/groups.module#GroupsModule'
      },
      {
        path: 'users',
        loadChildren: './views/users/users.module#UsersModule'
      },
      {
        path: 'vaults',
        loadChildren: './views/vaults/vaults.module#VaultsModule'
      }
    ]
  },

  // Handle all other routes
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
