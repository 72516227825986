import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableComponent } from './table.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { TableFooterComponent } from './table-footer/table-footer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [TableComponent, TableCellComponent, TableFooterComponent],
  exports: [TableComponent, TableCellComponent]
})
export class TableModule { }
