import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UserService } from 'document-vault-ts-angular-client';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  profileSubject: BehaviorSubject<any>;
  constructor(
    private userService: UserService
  ) {
    this.profileSubject = new BehaviorSubject(false);
  }

  clear() {
    this.profileSubject.next(false);
  }

  createUser(user) {
    return this.userService.addManagementUser(user);
  }

  getUsers(searchRequest) {
    return this.userService.getManagementUsers(searchRequest);
  }

  updateUsers(profile) {
    return this.userService.updateManagementUser(profile);
  }

  deleteUsers(userIds) {
    return this.userService.deleteUsers(userIds);
  }

  searchUser(searchText) {
    return this.userService.searchUser(searchText);
  }

  getProfile() {
    return this.userService.getProfile();
  }

  updateProfile(profile) {
    return this.userService.updateProfile(profile);
  }

  setProfile(profile) {
    this.profileSubject.next(profile);
  }

  resetPassword(email, password) {
    return this.userService.resetPassword(email, password);
  }

  getOrgProfile() {
    return this.userService.getOrgProfile();
  }
}
