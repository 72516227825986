import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class TableComponent implements OnInit, OnChanges {
  @Input() public config;
  @Input() public data;
  @Input() public pageInfo;
  @Input() public spacing = 0;
  @Output() public pagingNavigation: EventEmitter<any> = new EventEmitter<any>();
  @Output() public option: EventEmitter<any> = new EventEmitter<any>();

  public tableHeight: any;
  public tbodyHeight: any;

  public checkAll: Boolean = false;

  public paging = {
    total: 0,
    selected: 0,
    itemsPerPage: 20,
    totalPages: 0,
    pagingShowTotals: 10,
    pages: [],
    pageNo: 1
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageInfo'] && this.pageInfo) {
      this.settingPaging();
    }

    if (changes['data'] && this.data) {
      this.clearCheck();
    }
  }


  ngOnInit() {
    this.onResize();
  }

  onResize() {
    this.tableHeight = $(window).height() - this.spacing + 'px';
    this.tbodyHeight = $(window).height() - this.spacing - (38 + 42) + 'px';
  }



  checkAllCheckbox() {
    if (!this.data || this.data.length === 0) {
      return;
    }

    this.data.forEach(item => {
      item.checked = this.checkAll;
    });
    this.countChecked();
  }

  checkItem(item) {
    this.countChecked();
  }

  checkRow(item) {
    this.data.forEach(res => {
      res.checked = false;
    });
    item.checked = true;
    this.countChecked();
  }

  clearCheck() {
    if (this.data) {
      this.data.forEach(res => {
        res.checked = false;
      });
      this.countChecked();
    }
  }

  countChecked() {
    const checkedData = this.data.filter(res => {
      return res.checked;
    });

    this.paging.selected = checkedData.length;

    if (this.data.length > 0 && this.paging.selected === this.data.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }

    this.option.emit(JSON.parse(JSON.stringify(checkedData)));
  }

  pagingEven(data) {
    this.pagingNavigation.emit(data);
  }

  settingPaging() {
    this.paging.total = this.pageInfo.total;
    this.paging.pageNo = this.pageInfo.pageNo;
    this.paging.totalPages = this.pageInfo.totalPages;
    this.paging.pages = [];
    let endPageNumber, startPageNumber;
    startPageNumber = this.paging.pageNo - this.paging.pagingShowTotals / 2;
    endPageNumber = this.paging.pageNo + this.paging.pagingShowTotals / 2;
    if (startPageNumber < 0) {
      startPageNumber = 0;
      endPageNumber = this.paging.pagingShowTotals;
    }

    if (endPageNumber > this.paging.totalPages) {
      endPageNumber = this.paging.totalPages;
      if (endPageNumber > this.paging.pagingShowTotals) {
        startPageNumber = endPageNumber - this.paging.pagingShowTotals;
      }
    }

    for (let i = startPageNumber; i < endPageNumber; i++) {
      this.paging.pages.push(i);
    }
  }

}
