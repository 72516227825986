import { Component } from '@angular/core';
import { smoothlyMenu } from '../../app.helpers';

declare var jQuery: any;
@Component({
  selector: 'app-mini-navbar',
  templateUrl: './mini-navbar.component.html',
  styleUrls: ['./mini-navbar.component.scss']
})
export class MiniNavbarComponent {
  constructor() { }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }
}
