import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniNavbarComponent } from './mini-navbar.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [MiniNavbarComponent],
    exports: [
        MiniNavbarComponent
    ]
})
export class MiniNavbarModule { }
