import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { tap } from 'rxjs/operators';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private spinnerService: Ng4LoadingSpinnerService) {
   }
  public spinLoadingSize = 0;

  registerLoading() {
    this.spinLoadingSize++;
    this.spinnerService.show();
  }

  deregisterLoading() {
    this.spinLoadingSize--;
    if (this.spinLoadingSize <= 0) {
      this.spinnerService.hide();
      this.spinLoadingSize = 0;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const scope = this;
    this.registerLoading();
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          scope.deregisterLoading();
          return event;
        }
      }, error => {
        scope.deregisterLoading();
        return error;
      })
    );
  }
}
