import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap';

import { BasicLayoutComponent } from './basicLayout/basicLayout.component';
import { BlankLayoutComponent } from './blankLayout/blankLayout.component';

import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ModalsPayComponent } from './header/modals/modals-pay/modals-pay.component';
import { SharedModule } from '../directives/shared.module';


@NgModule({
  declarations: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    ModalsPayComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    SharedModule
  ],
  exports: [
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    FooterComponent,
    HeaderComponent
  ],
})

export class LayoutsModule { }
