import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BillService } from 'document-vault-ts-angular-client';

declare var Stripe: any;
declare var StripeCheckout: any;
declare var swal: any;
declare var $: any;
@Component({
  selector: 'app-modals-pay',
  templateUrl: './modals-pay.component.html',
  styleUrls: ['./modals-pay.component.scss'],
  providers: [BillingService, BillService]
})
export class ModalsPayComponent implements OnInit {
  @Output() public OnRefresh: EventEmitter<any> = new EventEmitter();
  public packageData: any;
  public packagekey = 0;
  public paymentMethodKey = 0;
  constructor(
    private configurationService: ConfigurationService,
    private billService: BillService,
    private billingService: BillingService
  ) {
    this.configurationService.setApiKeyToConfiguration(billService);
  }

  ngOnInit() {
    const scpoe = this;
    const handler = StripeCheckout.configure({
      key: 'pk_test_QHavV6uRXFYWANMDSjBTQrjo',
      image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
      locale: 'auto',
      token: function (token) {
        const billModeId = scpoe.packageData[scpoe.packagekey].id;
        scpoe.billingService.subscribeBillMode(billModeId, token.id, token.email).subscribe(() => {
          scpoe.OnRefresh.emit();
          $('#payModals').modal('hide');
        }, err => {
          if (err.error.message !== 'Failed to authenticate JWT token: No auth token') {
            swal('Pay error', err.error.message || 'Network error or server exception', 'error');
          } else {
            throw err;
          }
        });
      }
    });
    document.getElementById('customButton').addEventListener('click', function (e) {
      handler.open({
        name: 'My Own Document Vault',
        description: '',
        amount: scpoe.packageData[scpoe.packagekey].unitPrice
      });
      e.preventDefault();
    });

    // Close Checkout on page navigation:
    window.addEventListener('popstate', function () {
      handler.close();
    });
    // package data
    this.billingService.getBillModes().subscribe(res => {
      this.packageData = res;
    }, err => {
      if (err.error.message !== 'Failed to authenticate JWT token: No auth token') {
        swal('BillModes error', err.error.message || 'Network error or server exception', 'error');
      } else {
        throw err;
      }
    });
  }

  close_click() {
    $('#payModals').modal('hide');
  }

  // package active
  isActive(index) {
    this.packagekey = index;
  }

  // payment method active
  paymentMethod(index) {
    this.paymentMethodKey = index;
  }
}
