import { Component, OnInit } from '@angular/core';
import { detectBody, correctHeight } from 'src/app/app.helpers';


@Component({
  selector: 'app-basic-layout',
  templateUrl: 'basicLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicLayoutComponent implements OnInit {

  public ngOnInit(): any {
    detectBody();
    correctHeight();
  }

  public onResize() {
    detectBody();
    correctHeight();
  }

}
