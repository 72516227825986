import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss']
})
export class TableFooterComponent implements OnInit {
  @Input() public paging;
  @Output() public pagingNavigation: EventEmitter<any> = new EventEmitter<any>();

  public size = [10, 20, 50, 100];

  constructor() { }

  ngOnInit() {
  }

  changePerPage() {
    this.paging.selected = 0;
    this.paging.pageNo = 1;
    this.pagingNavigation.emit(this.paging);
  }

  changePage(item) {
    this.paging.selected = 0;
    this.paging.pageNo = item;
    this.pagingNavigation.emit(this.paging);
  }

  startPrevious() {
    this.paging.selected = 0;
    this.paging.pageNo = 1;
    this.pagingNavigation.emit(this.paging);
  }

  endNext() {
    this.paging.selected = 0;
    this.paging.pageNo = this.paging.totalPages;
    this.pagingNavigation.emit(this.paging);
  }

}
