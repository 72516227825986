import { Injectable } from '@angular/core';
import { AuthenticationService } from 'document-vault-ts-angular-client';

@Injectable()
export class AuthenticationApiService {

    constructor(
        private authenticationService: AuthenticationService
    ) {
    }

    login(user) {
        return this.authenticationService.getAuthenticationLogin(user.username, user.password);
    }

    logout() {
        return this.authenticationService.logout();
    }

    getDashboardInfo() {
        return this.authenticationService.getDashboardInfo();
    }
}
