import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

// 模板引用
import { LayoutsModule } from './layouts/layouts.module';
// Loading Spinner
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
// 路由
import { AppRoutingModule } from './app-routing.module';
// 国际化
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Error
import { ErrorLogService } from './services/error/error.log.service';
import { GlobalErrorHandler } from './services/error/error.handler.service';

// API
import { ApiModule, BASE_PATH } from 'document-vault-ts-angular-client';
// Interceptor
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { FilterUserPipe } from './pipe/filter-user.pipe';

// 国际化文件配置
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FilterUserPipe
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    Ng4LoadingSpinnerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApiModule
  ],
  providers: [
    ErrorLogService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: BASE_PATH, useValue: 'https://vault-api.demo-portal.com:7777/v1' },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
