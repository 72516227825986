import { Component, OnDestroy, AfterViewInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-blank-layout',
  templateUrl: 'blankLayout.template.html'
})
export class BlankLayoutComponent implements AfterViewInit, OnDestroy {

  ngAfterViewInit() {
    jQuery('body').addClass('gray-bg');
    jQuery('body').addClass('login-bg');
  }

  ngOnDestroy() {
    jQuery('body').removeClass('gray-bg');
    jQuery('body').removeClass('login-bg');
  }
}
